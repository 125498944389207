<template>
  <img :src="srcImage" style="transition: opacity .2s ease-out;" :style="{opacity: intersected ? '' : 0}" />
</template>

<script>
/**
 * @see https://stackoverflow.com/a/10930441
 */

function testWebP() {
	return new Promise((resolve, reject) => {
		try {
			var webP = new Image();
			webP.src =
				"data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
			webP.onload = webP.onerror = function() {
				resolve(webP.height === 2);
			};
		} catch (err) {
			resolve(false);
		}
	});
}

var webpSupportedGlobal = null;
var alreadyLoaded = [];

/**
 * @see https://alligator.io/vuejs/lazy-image/
 */
export default {
	props: {
		"src": String,
		"webp": String,
		"nolazy": {
			default: false,
		},
	},
	data: () => ({
		observer: null,
		intersected: false,
		webpTested: false,
		webpSupported: false,
	}),
	computed: {
		srcImage() {
			let intersected = this.intersected;
			let webpTested = this.webpTested;
			let webpSupported = this.webpSupported;
			let src = this.src;
			let webp = this.webp;
			if (!webp) {
				webp = src.replace(/\.(jpe?g|png|svg)$/, ".webp");
			}

			if (this.nolazy !== false) {
				return webpTested && webpSupported ? webp : src;
			}
			// src = require(src);
			return intersected && webpTested
				? webpSupported && webp
					? webp
					: src
				// : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
				: "";
		},
	},
	mounted() {
		if (~alreadyLoaded.indexOf(this.src)) {
			this.intersected = true;
			this.webpTested = true;
			this.webpSupported = webpSupportedGlobal;
		}
		if (webpSupportedGlobal === null) {
			testWebP().then(supported => {
				webpSupportedGlobal = supported;
				this.webpTested = true;
				this.webpSupported = supported;
			});
		} else {
			this.webpTested = true;
			this.webpSupported = webpSupportedGlobal;
		}
		if ('IntersectionObserver' in window && !this.intersected) {
			this.observer = new IntersectionObserver(entries => {
				const image = entries[0];
				if (image.isIntersecting) {
					this.intersected = true;
					alreadyLoaded.push(this.src);
					this.observer.disconnect();
				}
			});

			setTimeout(()=>{
				this.observer.observe(this.$el);
			}, 50)
		} else {
			this.intersected = true;
		}
	},
	destroyed() {
		if (IntersectionObserver in document) {
			this.observer.disconnect();
		}
	},
};
</script>